@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --shelby-blue: #5ec8e8;
  --other-blue: #128bb0;
  --purple: #614051;
  --dark-blue: #0D3B66;
  --dark-red: #a01a58;
  --hot-pink: #c92684;
  --card-bg: #faf7f9;
}

body {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow-x: auto;
  margin: 0;
  padding: 0;
}

.shelby-logo {
  margin: 0px;
  height: 80px;
  width: auto;
}

.shelby-logo:hover {
  transform: scale(1.1);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

/* adding spans for accessibility of icon links in header and hiding them from page */
.off-screen {
  position: absolute;
  left: -99999rem;
}

.slide-in-div {
  -webkit-animation-name: slidein;
  -webkit-animation-duration: 3s;
  /* -webkit-animation-iteration-count: 1; */
  -moz-animation-name: slidein;
  -moz-animation-duration: 3s;
  /* -moz-animation-iteration-count: 1; */
  animation-name: slidein;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  
}

h1.greeting {
  -webkit-animation-name: effect;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: effect;
  -moz-animation-duration: 1.5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  animation-name: effect;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 1%;
    width: 100%;
  }
}

@-webkit-keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 1%;
    width: 100%;
  }
}

@keyframes effect {
  from {
    background: -moz-linear-gradient(to right, var(--purple), var(--hot-pink));
    background: linear-gradient(to right, var(--purple), var(--hot-pink));
    -webkit-background-clip: text;
    background-clip: text;
  }

  to {
    background: -moz-linear-gradient(to right, var(--shelby-blue), #000FFF);
    background: linear-gradient(to right, var(--shelby-blue), #000FFF);
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@-webkit-keyframes effect {
from {
    background: -webkit-linear-gradient(to right, var(--purple), var(--hot-pink));
    -webkit-background-clip: text;
  }

  to {
    background: -webkit-linear-gradient(to right, var(--shelby-blue), #000FFF);
    -webkit-background-clip: text;
  }
}

.caption {
  position: absolute;
  bottom: 2%;
  left: 45%;
}

@media (max-width: 600px) {
  .caption {
    display: none;
  }
}


.fade-in {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.project-header {
  font-size: 45px;
}

.project-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 30px 100px;
}


a {
  text-decoration: none;
  font-weight: 500;
  color: rgb(193, 202, 241);
}

figcaption {
  font-weight: 500;
  color: rgb(221, 238, 238);
}

input[type=text],[type=email], textarea {
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 4px;
  background: #faf7f9;
  }


input:focus, textarea:focus {outline: none;}

.about-div {
  width: 85%;
  margin: 0;
  padding: 1.2em 0;
  background: #dcf0f2;
}

.about-div p {
  line-height: 1.6;
}

.about-div figure {
  position: relative;
}

.about-div figure small {
  position: absolute;
  bottom: 5%;
  left: 37%;
}

.about-div figure figcaption {
  font-size: 10px;
}

@media all and (max-width:960px) {
  .about-div {
    margin-left: 15%;
  }

  .about-div figure small {
    left: 5%;
  }
}

.subheader {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 86px;
}

/* The flip card container - set the width and height to whatever you want. 
We have added the border property to demonstrate that the flip itself goes out of the box on hover 
(remove perspective if you don't want the 3D effect 
*/
.flip-card {
  background-color: transparent;
  width: 450px;
  height: 450px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin: 25px;
}

.flip-card-back {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 45px;
  box-shadow: 0 8px 8px -4px var(--dark-blue);
  /* line-height: 150%; */
}

.flip-card-front {
  box-shadow: 0 8px 8px -4px var(--dark-blue);
}

.flip-card-front:hover, .flip-card-back:hover {
  cursor: pointer;
  box-shadow: -10px 10px 2px var(--dark-blue);
}

.flip-card-front img {
  height: 85%;
  width: 90%;
  border-radius: 5px;
  margin-top: 8px;
}

.flip-card-front button {
  background-color: var(--other-blue);
  box-shadow: 4px 6px 8px -4px rgba(0,0,0,0.6);
  color: #FFF;
  padding: 10px;
  border-radius: 7px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
}

.flip-card-front button:hover {
  background-color: var(--dark-blue);
  /* transform: sccd wg ale(.95); */
  font-size: 18px;
}

.flip-card-back h2 strong {
  display: block;
  font-size: 1.2rem;
  opacity: 0.75;
  color: var(--dark-red);
}

.flip-card-back h2 strong:hover {
  color: var(--dark-blue);
  font-size: 1.3rem;
}

.flip-card-back h2 {
  color: grey;
  font-size: .75rem;
}


.flip-card-back p {
  color: #000;
  font-size: 14px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:active .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: var(--card-bg);
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: var(--card-bg);
  transform: rotateY(180deg);
}